import {BACKGROUND_TYPE_IMAGE} from "../../page_background";
import sofia_s from "../../../images/contacts/sofia_s.jpg";
import sofia_m from "../../../images/contacts/sofia_m.jpg";
import sofia_l from "../../../images/contacts/sofia_l.jpg";
import sofia_xl from "../../../images/contacts/sofia_xl.jpg";
import london_s from "../../../images/contacts/london_s.jpg";
import london_m from "../../../images/contacts/london_m.jpg";
import london_l from "../../../images/contacts/london_l.jpg";
import london_xl from "../../../images/contacts/london_xl.jpg";
import mainz_s from "../../../images/contacts/mainz_s.jpg";
import mainz_m from "../../../images/contacts/mainz_m.jpg";
import mainz_l from "../../../images/contacts/mainz_l.jpg";
import mainz_xl from "../../../images/contacts/mainz_xl.jpg";
import helsinki_s from "../../../images/contacts/helsinki_s.jpg";
import helsinki_m from "../../../images/contacts/helsinki_m.jpg";
import helsinki_l from "../../../images/contacts/helsinki_l.jpg";
import helsinki_xl from "../../../images/contacts/helsinki_xl.jpg";
import nis_s from "../../../images/contacts/nis_s.jpg";
import nis_m from "../../../images/contacts/nis_m.jpg";
import nis_l from "../../../images/contacts/nis_l.jpg";
import nis_xl from "../../../images/contacts/nis_xl.jpg";

export const getContacts = newRoute => (
    [
        {
            index: 0,
            menuName: "Sofia",
            contactInfo: {
                city: "Sofia / HQ",
                address1: 'Headquarters',
                address2: '1172 Sofia, Bulgaria',
                address3: '120 Tintyava str.',
                tel: '+359 2 8704704',
                email: 'hello@droxic.com'
            },
            backgroundType: BACKGROUND_TYPE_IMAGE,
            backgroundImages: {
                "small": sofia_s,
                "mediumImg": sofia_m,
                "largeImg": sofia_l,
                "extraLargeImg": sofia_xl
            },
            newRoute: newRoute
        },
        {
            index: 1,
            menuName: "Niš",
            contactInfo: {
                city: "Niš",
                address1: 'Ulica Mladih 4, 1st fl.',
                tel: '+381 60 5704704',
                email: 'office-nis@droxic.com'
            },
            backgroundType: BACKGROUND_TYPE_IMAGE,
            backgroundImages: {
                "small": nis_s,
                "mediumImg": nis_m,
                "largeImg": nis_l,
                "extraLargeImg": nis_xl
            },
            newRoute: newRoute
        }
    ]
);

export const getSwiperParams = isMobile => {
    return isMobile ?
    {
        pagination: {
            type: 'bullets',
            bulletClass: "droxic-bullet-class",
            bulletActiveClass: "droxic-active-bullet-class"
        },
        slidesPerView: 1,
        direction: 'horizontal',
        speed: 300,
        effect: 'slide'
    } :
    {
        slidesPerView: 1,
        pagination: false,
        direction: 'vertical',
        speed: 600,
        effect: 'fade',
        fade: {
            crossFade: true
        }
    };
};
